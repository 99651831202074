import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/contents_list.css'
import '../../static/css/main_contents.css'
import '../../static/css/react-calendar-heatmap.css'
import '../../static/css/side.css'
import '../../static/css/footer.css'
import '../../static/css/font_awesome.css'
import '../../static/css/floating.css'
import React from 'react'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import { Floating } from '../components/Floating'
import { MainContents, ContentsList } from '../components/ArticleList'
import Seo from '../components/Seo'
import { url } from '../config/domain'
import { useStaticQuery, graphql } from 'gatsby'
import { getResizeImgUrl } from '../util/format'
import loadable from '@loadable/component'

const Script = loadable(() => import('../components/Adsense/Script'))

export default function ArticleList({ location }: any): JSX.Element {
  const data = useStaticQuery(
    graphql`
      query {
        allArticle(sort: { order: DESC, fields: updatedAt___seconds }) {
          edges {
            node {
              category
              description
              id
              thumbnail
              title
              article
              updatedAt {
                seconds
              }
            }
          }
        }
      }
    `
  )
  const mainContents = data && data.allArticle ? data.allArticle.edges.slice(0, 3) : []
  const contentsList = data && data.allArticle ? data.allArticle.edges.slice(3) : []

  const locatePath = location.pathname.split('/')
  return (
    <div>
      <div className="wrapper">
        <Seo
          title={'KURORO BLOG'}
          description={
            'KURORO BLOGは、「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有サイトです。'
          }
          url={url}
          imgList={[
            getResizeImgUrl(mainContents[0].node.thumbnail, '120x120'),
            getResizeImgUrl(mainContents[0].node.thumbnail, '350x232'),
          ]}
          noIndex={false}
          isTopPage={true}
        />
        <Header location={locatePath} />
        <MainContents articles={mainContents} />
        <ContentsList articles={contentsList} location={locatePath} title={'記事一覧'} />
        <Footer />
      </div>
      <Floating />
      <Script />
    </div>
  )
}
