import React from 'react'
import { getResizeImgUrl, timestampToTime, getEstimatedTime } from '../../util/format'

const MainContents = (props: { articles: any }): JSX.Element => {
  return (
    <>
      <section className="mainContents__section">
        {props.articles &&
          props.articles.map(
            (
              article: {
                node: {
                  category: string
                  description: string
                  id: string
                  thumbnail: string
                  title: string
                  article: string
                  updatedAt: {
                    seconds: number
                  }
                }
              },
              idx: number
            ) => {
              const time = timestampToTime(article.node.updatedAt.seconds)
              const estimatedTime = getEstimatedTime(article.node.article)
              const img = (
                <img
                  className="mainContents__img"
                  alt={article.node.title}
                  src={getResizeImgUrl(article.node.thumbnail, '120x120')}
                  srcSet={getResizeImgUrl(article.node.thumbnail, '350x232') + ' 2x'}
                  referrerPolicy="no-referrer"
                  height={200}
                  width={340}
                />
              )
              return (
                <article className="mainContents__wrapper" key={idx}>
                  <a href={'/' + article.node.category + '/' + article.node.id + '/'} className="mainContents__a">
                    <div>
                      {img}
                      <noscript>
                        <style>{`.lazyload-placeholder { display: none; }`}</style>
                        {img}
                      </noscript>
                    </div>
                    <div>
                      <div className="mainContents__title">{article.node.title}</div>
                      <div className="mainContents__description">{article.node.description}</div>
                      <div className="mainContents__timestamp">
                        {article.node.category + ' ｜ ' + estimatedTime + ' min read ｜ '}
                        <i className="fas fa-clock" aria-hidden="true" />
                        {'  '}
                        <time dateTime={time}>{time}</time>
                      </div>
                    </div>
                  </a>
                </article>
              )
            }
          )}
      </section>
    </>
  )
}
export default MainContents
