import React from 'react'
import { getResizeImgUrl, timestampToTime, convertDesc, getEstimatedTime } from '../../util/format'
import { Side } from '../Side'
import LazyLoad from 'react-lazyload'
import loadable from '@loadable/component'

const DisplayAdsense = loadable(() => import('../Adsense/DisplayAdsense'))

const articleComponents = (articles: any) => {
  if (articles.length > 0) {
    return articles.map(
      (
        article: {
          node: {
            category: string
            description: string
            id: string
            thumbnail: string
            title: string
            article: string
            updatedAt: {
              seconds: number
            }
          }
        },
        idx: number
      ) => {
        const time = timestampToTime(article.node.updatedAt.seconds)
        const estimatedTime = getEstimatedTime(article.node.article)
        const img = (
          <>
            <LazyLoad>
              <img
                className="contentsList__img"
                alt={article.node.title}
                src={getResizeImgUrl(article.node.thumbnail, '120x120')}
                srcSet={getResizeImgUrl(article.node.thumbnail, '350x232') + ' 2x'}
                referrerPolicy="no-referrer"
                height={'100%'}
                width={310}
              />
            </LazyLoad>
            <noscript>
              <style>{`.lazyload-placeholder { display: none; }`}</style>
              <img
                className="contentsList__img"
                alt={article.node.title}
                src={getResizeImgUrl(article.node.thumbnail, '120x120')}
                srcSet={getResizeImgUrl(article.node.thumbnail, '350x232') + ' 2x'}
                referrerPolicy="no-referrer"
                height={'100%'}
                width={310}
              />
            </noscript>
          </>
        )
        return (
          <article className="contentsList__contents" key={idx}>
            <a
              href={'/' + article.node.category + '/' + article.node.id + '/'}
              className="contentsList__a"
              aria-label={article.node.title + '記事へのリンク'}
            >
              <div className="contentsList__contentsLeft">{img}</div>
              <div className="contentsList__contentsRight">
                <div className="contentsList__title">{article.node.title}</div>
                <div className="contentsList__description">{convertDesc(article.node.description, 50)}</div>
                <div className="contentsList__timestamp">
                  {article.node.category + ' ｜ ' + estimatedTime + ' min read ｜ '}
                  <i className="fas fa-clock" aria-hidden="true" />
                  {'  '}
                  <time dateTime={time}>{time}</time>
                </div>
              </div>
            </a>
          </article>
        )
      }
    )
  } else {
    return
  }
}

const ContentsList = (props: { articles: any; location: Array<string>; title: string }): JSX.Element => {
  return (
    <div className="contentsList__flexContainer">
      <section className="contentsList__contentsListWrapper">
        <h2 className="contentsList__h2">{props.title}</h2>
        {articleComponents(props.articles)}

        <DisplayAdsense
          slot={{
            PC: '1129669449',
            Mobile: '5927534704',
          }}
          position={{
            PC: {
              top: 24,
              bottom: 0,
              left: 0,
              right: 0,
            },
            Mobile: {
              top: 16,
              bottom: 16,
              left: 0,
              right: 0,
            },
          }}
          type={{
            PC: 'display-auto',
            Mobile: 'display-auto',
          }}
          isSticky={false}
        />
      </section>
      <Side
        category={''}
        articleId={''}
        location={props.location}
        rankingBottomPC={'7392453758'}
        rankingBottomMobile={'3533099790'}
        betweenSelfAndCategoryLIstPC={'9728793318'}
        betweenSelfAndCategoryLIstMobile={'3885972523'}
        sideFixedPC={'4983771084'}
        sideFixedMobile={'2395785125'}
      />
    </div>
  )
}
export default ContentsList
