import React from 'react'

const Floating = (): JSX.Element => {
  // 参考 : https://zenn.dev/sunaoproducts/articles/1818c48f60dc81
  const returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <span onClick={returnTop} className="returnTop">
      <a>
        <i className="fa fa-chevron-up" aria-hidden="true" />
      </a>
    </span>
  )
}
export default Floating
